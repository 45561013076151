import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import components for clarity
import Home from "./pages/Home";
import Music from "./pages/Music";
import Contato from "./pages/Contato";
import Test from "./pages/Test";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import NotFound from "./components/NotFound";
import Tools from "./pages/Tools"; // Assume this component exists

const AppRoutes = () => {
    const routes = [
        { path: "/", exact: true, element: <Home /> },
        { path: "/tools", exact: true, element: <Tools/>},// Added exact
        { path: "/music", exact: true, element: <Music /> }, // Added exact
        { path: "/test", exact: true, element: <Test /> }, // Added exact
        { path: "/contato", exact: true, element: <Contato /> },
        { path: "*", element: <NotFound /> }, // Catch-all route
    ];

    return (
        <Router>
            <NavBar />

            <Routes>
                {routes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.element} />
                ))}
            </Routes>

            <Footer />
        </Router>
    );
};

export default AppRoutes;
